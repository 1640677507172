var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VContainer',{staticClass:"scheduler",attrs:{"fluid":"","pa-0":"","fill-height":""}},[_c('WithCalendar',{scopedSlots:_vm._u([{key:"default",fn:function({
        reports,
        reportsByForemanId,
        isCalendarLoading,
        areLocationsLoading,
        foremen,
        didTimeout,
      }){return _c('div',{staticClass:"calendar-inner fill-height"},[_c('AvailableLocationsList',{attrs:{"loading":areLocationsLoading}}),_c('VLayout',{attrs:{"wrap":"","fill-height":""}},[_c('VFlex',{attrs:{"sm12":""}},[_c('VLayout',{attrs:{"row":"","align-space-around":"","fill-height":""}},[_c('CrewCalendar',{attrs:{"foremen":foremen,"did-timeout":didTimeout,"are-reports-loading":isCalendarLoading,"reports-by-foreman-id":reportsByForemanId,"reports":reports}})],1)],1)],1)],1)}}])}),_c('ScheduleInThePastModal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <h3 class="column-title">
    <slot/>    
  </h3>

</template>

<script>
export default {

  name: 'ColumnTitle',

  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
</style>
<template>
  <div class="location-search">
    <BaseInput
      v-model="searchTerm"
      class="px-2 pt-0 search-field"
      label="Search"
      color="white"
      hide-details
      prepend-inner-icon="search"
      flex
      single-line
      dark
      dense
      clearable
      @input="searchTerm = $event"
      @click:clear="searchTerm = null"
    />
    <VSwitch
      v-model="isPreciseSearchOn"
      class="precise-search-switch ma-2 pt-0"
      label="Precise Search"
      dark
      hide-details
      title="Enable Precise search to match search terms exactly."
    />
  </div>
</template>

<script>
import { VSwitch } from 'vuetify/lib'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})
export default {
  name: 'LocationSearch',
  components: {
    VSwitch,
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields(['isPreciseSearchOn', 'searchTerm']),
  },
}
</script>

<style lang="scss">
.precise-search-checkbox .v-label {
  font-size: 1rem;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'location-data',
    { 'sm-data': _vm.sm },
    { mini: _vm.isLocationDrawerMini },
    { hover: _vm.hover },
    { 'exceeds-width': _vm.exceedsWidth },
    { 'hidden-while-dragging': _vm.hideOnDrag },
    { 'is-for-print': _vm.isForPrint },
  ],on:{"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave}},[_vm._t("icon"),_c('VTooltip',{attrs:{"disabled":_vm.isTooltipDisabled,"right":"","lazy":"","max-width":230,"nudge-left":_vm.nudgeLeft},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({ref:"activator",staticClass:"value"},on),[_vm._v(_vm._s(_vm.value))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.value))])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <BaseDropdown
      v-model="selectedShiftType"
      title="Shift Type"
      :options="shiftTypes"
      color="blue"
      hide-details
      class="my-2"
      :solo="false"
      clearable
      @selected="selectedShiftType = $event"
    />
    <BaseDropdown
      v-model="selectedJobType"
      title="Job Type"
      :options="jobTypes"
      hide-details
      class="my-2"
      :solo="false"
      clearable
      @selected="selectedJobType = $event"
    />
    <VAutocomplete
      v-model="selectedClient"
      label="Filter by Client"
      :items="clients"
      hide-details
      class="my-2"
      :solo="false"
      :persistent-hint="false"
      clearable
    />
    <VSwitch
      v-model="isShowingFiberOnly"
      class="fiber-only-checkbox pt-3 my-2"
      label="Show Fiber Jobs Only"
      hide-details
    />
  </div>
</template>

<script>
import VAutocomplete from '@vuetify/VAutocomplete'
import { JobTypes, FiberJobTypes } from '@constants/knack'
import _pickBy from 'lodash/pickBy'
import { VSwitch } from 'vuetify/lib'

import { createHelpers } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  name: 'LocationFilters',
  components: {
    VAutocomplete,
    VSwitch,
  },
  props: {
    clients: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      shiftTypes: [{ name: 'M-F' }, { name: 'Sat/Sun' }, { name: 'Nights' }],
    }
  },
  computed: {
    ...mapGetters(['getLocationFiltersAreOn']),
    ...mapFields([
      'isShowingFiberOnly',
      'selectedShiftType',
      'selectedJobType',
      'selectedClient',
      'searchTerm',
    ]),
    jobTypes() {
      let availableJobTypes = this.isShowingFiberOnly
        ? this.fiberOnlyJobTypes
        : JobTypes
      return Object.values(availableJobTypes).map((type) => ({
        name: type,
      }))
    },
    fiberOnlyJobTypes() {
      return _pickBy(JobTypes, (v) => FiberJobTypes.includes(v))
    },
  },
}
</script>

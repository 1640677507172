<template>
  <VNavigationDrawer
    :mini-variant="isLocationDrawerMini"
    permanent
    stateless
    hide-overlay
    app
    light
    width="250"
    mini-variant-width="150"
    class="transparent"
  >
    <BaseButton
      :md-icon="isLocationDrawerMini ? 'east' : 'west'"
      text-color="white"
      color="grey darken-3"
      small
      fab
      icon
      depressed
      no-icon-padding
      class="drawer-close-button mt-2 v-btn--xs"
      :title="isLocationDrawerMini ? 'Expand Sidebar' : 'Shrink Sidebar'"
      @click="isLocationDrawerMini = !isLocationDrawerMini"
    />
    <div
      :class="[
        'locations-wrap pt-3 section-wrap',
        { mini: isLocationDrawerMini },
      ]"
    >
      <ColumnTitle>Locations ({{ sortedLocations.length }})</ColumnTitle>
      <LocationSearch
        @update="searchTerm = $event"
        @togglePrecise="isPreciseSearchOn = $event"
      />

      <LocationFilterMenu />

      <VContainer fluid grid-list-md py-0 px-1>
        <VLayout
          :class="['locations-list-wrap', { 'delete-zone': isDeleteZone }]"
          align-center
        >
          <transition name="fade-fast" mode="out-in">
            <VFlex v-if="loading">
              <BaseSpinner message="Loading Locations" />
            </VFlex>
            <VFlex v-else-if="sortedLocations.length">
              <Draggable
                v-model="sortedLocations"
                :class="['locations']"
                draggable=".location-item:not(.on-hold)"
                :sort="false"
                :group="{ name: 'locations', pull: 'clone' }"
                :move="onMove"
                :disabled="!canEditScheduler"
                @start="onStart"
                @end="endDrag"
                @change="onChange"
              >
                <LocationItem
                  v-for="location in sortedLocations"
                  :key="location.ID"
                  :location="location"
                />
              </Draggable>
            </VFlex>
            <VFlex v-else class="pa-4">
              <h3 class="dark white--text">No Locations Found</h3>
            </VFlex>
          </transition>
        </VLayout>
      </VContainer>
    </div>
  </VNavigationDrawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Draggable from 'vuedraggable'

import LocationFilterMenu from '@modals/LocationFilterMenu'
import LocationSearch from '@scheduler/LocationSearch'

import LocationItem from './LocationItem'
import ColumnTitle from './ColumnTitle'
import _get from 'lodash/get'

import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  name: 'AvailableLocationsList',
  components: {
    Draggable,
    LocationItem,
    ColumnTitle,
    LocationSearch,
    LocationFilterMenu,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'canEditScheduler',
      'isDragging',
      'theDragObject',
      'getSortedLocations',
    ]),
    ...mapFields(['isLocationDrawerMini']),
    sortedLocations: {
      set() {
        // Don't do nothin. Just here to allow
        // dragging off another <Draggable> into this element
      },
      get() {
        return this.getSortedLocations
      },
    },

    isDeleteZone() {
      let objectLocationData = _get(this, 'theDragObject.locationData', false)
      if (objectLocationData) {
        let theReport = this.theDragObject
        if (
          theReport.NUMBER_OF_EQUIPMENT_USAGES > 0 ||
          theReport.NUMBER_OF_LABOR_ASSIGNMENTS > 0 ||
          theReport.NUMBER_OF_MATERIAL_USAGES > 0
        ) {
          return false
        }
      }

      return this.isDragging && objectLocationData
    }, // isDeleteZone
  }, // computed
  methods: {
    ...mapActions([
      'startDrag',
      'endDrag',
      'setTheDragObject',
      'deleteSchedReport',
      'unsetReportHover',
      'clearLocationFilters',
    ]),
    onStart(evt) {
      // console.log('reports', this.reports)
      // console.log('onStart evt', evt)
      let theLocation = this.getSortedLocations[evt.oldIndex]
      // console.log('theLocation', theLocation)
      this.setTheDragObject(theLocation)
      this.startDrag()
    }, // onStart

    onMove(evt) {
      if (evt.to === evt.from || evt.to.classList.contains('drop-zone')) {
        return true
      }
      return false
    }, // onMove

    async onChange(evt) {
      if (evt.added) {
        // If newly added item is a report, delete it from the cal
        if (evt.added.element.locationData) {
          try {
            await this.deleteSchedReport(evt.added.element)
            this.$notify({ type: 'success', title: 'Report Deleted' })
          } catch (error) {
            this.$notify({ type: 'warn', title: 'Could not delete report' })
            throw error
          }
          await this.unsetReportHover()
        }
      }
      return false
    }, // onChange
  }, // methods
}
</script>

<style lang="scss" scoped>
.locations-wrap {
  flex: 1 1 15%;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  background: lighten(black, 60%);
  transition: all 0.3s ease;
  .column-title {
    color: white;
  }
  &.mini {
    padding-top: 50px !important;
  }
}
.locations-title {
  align-self: center;
}
.locations {
  display: flex;
  flex-wrap: wrap;
}
.md-menu {
  width: 100%;
  margin-bottom: 5px;
}
.locations-list-wrap {
  flex-basis: 80%;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-x: hidden;
  &.delete-zone {
    background: lighten(red, 15);
  }
}
.drawer-close-button {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}
</style>

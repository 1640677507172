<template>
  <VDialog v-model="showScheduleInThePastModal" :max-width="400">
    <VCard>
      <VCardTitle class="orange accent-2 white--text">
        <VFlex xs12><h2>Confirm Move to the Past</h2></VFlex>
      </VCardTitle>
      <VCardText class="font-weight-medium pa-5">
        <div
          >You're trying to create or move a Daily Report to a date in the past.
          You must confirm this action.</div
        >
      </VCardText>
      <VCardActions class="justify-end">
        <BaseButton
          color="blue darken-2"
          depressed
          :loading="loading"
          :disabled="loading"
          @click="scheduleInThePastAction"
          >Confirm</BaseButton
        >
        <BaseButton
          color="blue darken-2"
          outline
          :disabled="loading"
          @click="showScheduleInThePastModal = false"
          >Cancel</BaseButton
        >
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import VDialog from '@vuetify/VDialog'
import { VCardText, VCardActions } from '@vuetify/VCard'
import { mapActions } from 'vuex'

import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  name: 'ScheduleInThePastModal',
  components: { VDialog, VCardText, VCardActions },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapFields([
      'showScheduleInThePastModal',
      'confirmScheduleInThePast',
      'moveToThePastPayload',
    ]),
  },
  watch: {
    showScheduleInThePastModal(isVisible) {
      if (!isVisible) {
        this.resetConfirmationProcess()
      }
    }, // showScheduleInThePastModal
  },

  methods: {
    ...mapActions(['addSchedReport']),
    async scheduleInThePastAction() {
      this.loading = true
      let report = this.moveToThePastPayload.draggedObj
      let wasMovedOrCreated =
        typeof report.DAILY_REPORT_NAME !== 'undefined' ? 'moved' : 'created'

      try {
        await this.addSchedReport(this.moveToThePastPayload)
        this.showScheduleInThePastModal = false
        this.resetConfirmationProcess()
      } catch (error) {
        this.$notify({
          title: `Report could not be ${wasMovedOrCreated}`,
          type: 'warn',
        })
      } finally {
        this.loading = false
      }
    },
    resetConfirmationProcess() {
      this.moveToThePastPayload = null
      this.confirmScheduleInThePast = false
    }, // resetConfirmationProcess
  },
}
</script>

<template>
  <div
    :class="[
      'location-data',
      { 'sm-data': sm },
      { mini: isLocationDrawerMini },
      { hover },
      { 'exceeds-width': exceedsWidth },
      { 'hidden-while-dragging': hideOnDrag },
      { 'is-for-print': isForPrint },
    ]"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <slot name="icon" />
    <VTooltip
      :disabled="isTooltipDisabled"
      right
      lazy
      :max-width="230"
      :nudge-left="nudgeLeft"
    >
      <template v-slot:activator="{ on }">
        <span ref="activator" class="value" v-on="on">{{ value }}</span>
      </template>
      <span>{{ value }}</span>
    </VTooltip>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import VTooltip from '@vuetify/VTooltip'

const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})
export default {
  name: 'LocationData',
  components: { VTooltip },
  props: {
    value: {
      type: String,
      default: '',
    },
    sm: {
      type: Boolean,
      default: false,
    },
    hideOnDrag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
      exceedsWidth: false,
    }
  },
  computed: {
    ...mapFields(['isLocationDrawerMini']),
    isTooltipDisabled() {
      return !this.exceedsWidth
    },
    nudgeLeft() {
      if (!this.exceedsWidth) return 0
      return this.$refs.activator.offsetWidth - this.$el.offsetWidth
    },
    isForPrint() {
      return this.$route.path === '/print-fiber-locations'
    },
  },
  methods: {
    checkWidth() {
      const child = this.$refs.activator
      const parent = this.$el.parentNode

      let elemPadding = 18
      this.exceedsWidth = child.offsetWidth + elemPadding > parent.offsetWidth
    },
    handleMouseOver() {
      this.hover = true
      this.checkWidth()
    },
    handleMouseLeave() {
      this.hover = false
      this.checkWidth()
    },
  },
}
</script>

<style lang="scss">
// for parent component
.location-item {
  &.sortable-ghost {
    .hidden-while-dragging {
      display: none;
    }
  }
}

.location-data {
  flex: 0 0 100%;
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;
  line-height: 1.2;
  overflow: hidden;
  margin-bottom: 2px;
  &.sm-data {
    font-size: 12px;
  }
  &.permit-hours {
    font-weight: bold;
  }

  &.mini {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.is-for-print {
    overflow: visible;
    white-space: normal;
    text-overflow: initial;
    line-height: 1.1;
    font-size: 13px;
    &.sm-data {
      font-size: 11px;
    }
  }
}
</style>

<template>
  <SchedulerLayout>
    <Scheduler />
  </SchedulerLayout>
</template>

<script>
import SchedulerLayout from '@layouts/schedulerLayout'
import { mapActions } from 'vuex'
import Scheduler from '@components/SchedulerView/Scheduler'
export default {
  name: 'SchedulerView',
  metaInfo: {
    title: 'Scheduler',
  },
  components: { SchedulerLayout, Scheduler },
  beforeMount() {
    this.setIsPrintMode(false)
  },
  methods: mapActions(['setIsPrintMode']),
}
</script>

<template>
  <VMenu
    v-model="isLocationFilterMenuOpen"
    :close-on-content-click="false"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <BaseButton
        :color="getLocationFiltersAreOn ? 'green accent-4' : 'gray'"
        :text-color="getLocationFiltersAreOn ? 'white' : 'black'"
        depressed
        small
        md-icon="filter_alt"
        md-icon-position="left"
        class="no-print"
        v-on="on"
        >Filter</BaseButton
      >
      <transition name="fade-fast">
        <BaseButton
          v-if="getLocationFiltersAreOn"
          class="clear-filters-btn"
          md-icon="close"
          round
          small
          outline
          color="white"
          @click="clearLocationFilters"
          >Clear{{ !isLocationDrawerMini ? ' Filters' : '' }}</BaseButton
        >
      </transition>
    </template>
    <VCard class="text-xs-left">
      <ModalCloseButton @click="isLocationFilterMenuOpen = false" />
      <VCardTitle class="blue darken-1 white--text">
        <VFlex xs12>
          <h2>
            Filter Locations
          </h2>
        </VFlex>
        <VFlex xs12>
          <h3>{{ getSortedLocations.length }} Locations Found</h3>
        </VFlex>
      </VCardTitle>

      <VCardText>
        <LocationFilters :clients="clientList" />
      </VCardText>

      <VCardActions>
        <VSpacer />
        <BaseButton
          depressed
          color="blue darken-1"
          @click="isLocationFilterMenuOpen = false"
          >OK</BaseButton
        >
        <BaseButton
          depressed
          color="blue"
          :disabled="!getLocationFiltersAreOn"
          outline
          @click="clearAndClose"
          >Clear All</BaseButton
        >
      </VCardActions>
    </VCard>
  </VMenu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { VCardText, VCardActions } from '@vuetify/VCard'
import { createHelpers } from 'vuex-map-fields'
import LocationFilters from '@scheduler/LocationFilters'
import ModalCloseButton from '@modals/ModalCloseButton'

const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  name: 'LocationFilterMenu',
  components: {
    VCardText,
    VCardActions,
    ModalCloseButton,
    LocationFilters,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getAllLocations',
      'getLocationFiltersAreOn',
      'getSortedLocations',
    ]),
    ...mapFields([
      'isLocationFilterMenuOpen',
      'isLocationDrawerMini',
      'isPreciseSearchOn',
      'searchTerm',
    ]),
    clientList() {
      let clientSet = new Set()
      this.getAllLocations.forEach((loc) => {
        if (loc.CLIENT_NAME.length) {
          clientSet.add(loc.CLIENT_NAME)
        }
      })
      let clientsArray = Array.from(clientSet).sort()
      return clientsArray
    }, // clientList
  },
  methods: {
    ...mapActions(['clearLocationFilters']),
    clearAndClose() {
      this.clearLocationFilters()
      this.isLocationFilterMenuOpen = false
    },
  },
}
</script>

<template>
  <VContainer fluid pa-0 class="scheduler" fill-height>
    <WithCalendar>
      <div
        slot-scope="{
          reports,
          reportsByForemanId,
          isCalendarLoading,
          areLocationsLoading,
          foremen,
          didTimeout,
        }"
        class="calendar-inner fill-height"
      >
        <AvailableLocationsList :loading="areLocationsLoading" />
        <VLayout wrap fill-height>
          <VFlex sm12>
            <VLayout row align-space-around fill-height>
              <CrewCalendar
                :foremen="foremen"
                :did-timeout="didTimeout"
                :are-reports-loading="isCalendarLoading"
                :reports-by-foreman-id="reportsByForemanId"
                :reports="reports"
              />
            </VLayout>
          </VFlex>
        </VLayout>
      </div>
    </WithCalendar>
    <ScheduleInThePastModal />
  </VContainer>
</template>

<script>
import { mapGetters } from 'vuex'

import ScheduleInThePastModal from '@components/Modals/ScheduleInThePastModal'

import CrewCalendar from '@scheduler/CrewCalendar'
import WithCalendar from '@dataProviders/WithCalendar'
import AvailableLocationsList from '@scheduler/AvailableLocationsList'

export default {
  name: 'Scheduler',
  components: {
    AvailableLocationsList,
    WithCalendar,
    CrewCalendar,
    ScheduleInThePastModal,
  },
  computed: {
    ...mapGetters([
      'canEditScheduler',
      'canViewScheduler',
      'getIsSchedulerLoading',
    ]),
  },
}
</script>

<style lang="scss">
.scheduler {
  color: $gray-dark;
  $status-border-width: 4px;
  $night: #136085;
  .location-item,
  .shift {
    padding-left: $status-border-width * 2;
    border-radius: 4px;
    &.protected {
      position: relative;
      overflow: hidden;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: $status-border-width;
        content: '';
        background-color: $red;
      }
      .left-icon {
        position: absolute;
        bottom: 2px;
        left: $status-border-width;
      }
    }
    &.night-shift {
      // background: white;
      border: 1px solid $night;
      .night-shift-icon {
        position: absolute;
        right: 2px;
        bottom: 2px;
        transform: rotate(30deg);
      }
    } // .night-shift

    &.has-notes {
      .has-notes-icon {
        position: absolute;
        top: 2px;
        left: 4px;
      }
      &.protected {
        .has-notes-icon {
          left: 8px;
        }
      }
    }
    .new-permit-icon {
      z-index: 2;
      position: absolute;
      top: 2px;
      right: 2px;
      border-radius: 100% !important;
    }
  }
  .location-item {
    &.night-shift {
      border: 2px solid $night;
    }
  }
}
</style>

<template>
  <VCard
    :class="[
      'location-item',
      { protected: location.PROTECTED === 'Yes' },
      { 'not-protected': location.PROTECTED === 'No' },
      { 'half-protected': location.PROTECTED === 'Both' },
      { 'night-shift': isNightShift },
      { 'is-on-cal': isOnCalendar },
      { 'modal-open cyan accent-3 black--text': showLocationModal },
      { 'on-hold': location.ON_HOLD },
      { 'has-notes': hasNotes },
      { mini: isLocationDrawerMini },
    ]"
    :flat="isOnCalendar"
    @click.stop="handleLocationItemClick"
    @mouseover="hoverFn(location.ID)"
    @mouseleave="unhoverFn"
  >
    <VAvatar v-if="location.ON_HOLD" class="on-hold-icon" color="orange">
      <VIcon color="white" :small="isLocationDrawerMini"
        >pause_circle_filled</VIcon
      >
    </VAvatar>

    <LocationData class="job-number" sm :value="location.JOB_NUMBER" />
    <LocationData class="location-id" sm :value="location.IDENTIFIER" />
    <LocationData class="location-client" sm :value="location.CLIENT_NAME" />
    <LocationData
      v-if="isFiberJob && !isLocationDrawerMini"
      hide-on-drag
      class="body-1"
      :value="fiberJobAttrs.join(' | ')"
    />
    <LocationData
      v-if="hasNotes && !isLocationDrawerMini"
      sm
      :value="location.PROJECT_MANAGER_NOTES"
    >
      <template v-slot:icon>
        <VIcon small color="#c837d5" class="d-inline mr-1">note</VIcon>
      </template>
    </LocationData>

    <LocationData
      v-if="workHours.length && !isLocationDrawerMini"
      hide-on-drag
      class="permit-hours"
      :value="workHours.join(' | ')"
    />
    <LocationData sm class="text-xs-left">
      <template v-slot:icon>
        <VIcon
          v-if="hasNotes && isLocationDrawerMini"
          small
          color="#c837d5"
          class="d-inline mx-1"
          >note</VIcon
        >
        <VIcon
          v-if="location.VZ_INSPECTION_DATE"
          small
          color="#2c70ea"
          class="d-inline mx-1"
          >person_search</VIcon
        >
        <VIcon
          v-if="location.INSTALL_5G_FOUNDATION_DATE"
          small
          color="#de7113"
          class="d-inline mx-1"
          style="max-width: 20px"
          >5g</VIcon
        >
      </template>
    </LocationData>
    <VTooltip :disabled="!isLocationDrawerMini" right lazy>
      <template v-slot:activator="{ on }">
        <VAvatar
          v-if="hasNewPermitAvailable"
          right
          size="18"
          class="new-permit-icon mr-1 mt-1"
          color="white"
          v-on="on"
        >
          <VIcon color="#ffb841">
            new_releases
          </VIcon>
        </VAvatar>
      </template>
      <span>Permit Apprvd: {{ location.DATE_PERMIT_APPROVED.date }}</span>
    </VTooltip>

    <VIcon v-if="isNightShift" small color="#136085" class="night-shift-icon"
      >brightness_3</VIcon
    >
    <LocationModal
      v-if="showLocationModal"
      :location="location"
      :show="showLocationModal"
      @hide="showLocationModal = false"
    ></LocationModal>
  </VCard>
</template>

<script>
import LocationModal from '@modals/LocationModal'
import VTooltip from '@vuetify/VTooltip'
import LocationData from '@scheduler/LocationData'

import { JobTypes } from '@constants/knack'
import { VAvatar } from 'vuetify/lib'
import _get from 'lodash/get'
import { createHelpers } from 'vuex-map-fields'
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})

export default {
  components: { LocationModal, VAvatar, VTooltip, LocationData },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLocationModal: false,
      hover: false,
    }
  },
  computed: {
    ...mapGetters(['getReportByLocationId']),
    ...mapFields(['isLocationDrawerMini']),
    isNightShift() {
      return this.location.PERMIT__WORK_HOURS
        ? this.location.PERMIT__WORK_HOURS.includes('Nights')
        : false
    }, // isNightShift
    fiberJobAttrs() {
      if (!this.isFiberJob) return []
      let attrs = [this.location.LF]
      attrs.push(this.protectedStatus)
      attrs.push(_get(this, 'location.JOB_TYPE', []).join(', '))
      if (this.requiresVZinspection) attrs.push('VZ')
      if (this.requires5G) attrs.push('5G Fndtn')
      return attrs
    },
    protectedStatus() {
      let lookup = {
        Yes: 'P',
        No: 'NP',
        Both: 'P/NP',
        '': '–',
      }
      let protectedValue = _get(this, 'location.PROTECTED', '')
      return lookup[protectedValue] || false
    }, // protectedStatus
    requires5G() {
      return this.location.JOB_REQUIRES_5G_FOUNDATION === 'Yes' ? '5G' : false
    }, // requires5G
    requiresVZinspection() {
      return this.location.VZ_INSPECTION_REQUIRED === true ? 'VZ' : false
    }, // requiresVZinspection
    workHours() {
      return this.location.PERMIT__WORK_HOURS.length
        ? this.location.PERMIT__WORK_HOURS
        : ['M-F (Default)']
    }, // workHours
    isOnCalendar() {
      return this.getReportByLocationId(this.location.ID).length > 0
    }, // isOnCalendar
    jobType() {
      return _get(this, 'location.JOB_TYPE[0]', '')
    },
    isCivilJob() {
      return [JobTypes.CIVIL_WORK, JobTypes.NYSDOT].includes(this.jobType)
    },
    isFiberJob() {
      return [
        JobTypes.FIBER,
        JobTypes.MICRO,
        JobTypes.MICRO_REPAIR,
        JobTypes.POWER_DIG,
        JobTypes.EMERGENCY,
      ].includes(this.jobType)
    },
    isMicroFiberJob() {
      return [JobTypes.MICRO, JobTypes.MICRO_REPAIR].includes(this.jobType)
    },
    hasNotes() {
      return this.location.PROJECT_MANAGER_NOTES.length > 0
    },
    hasNewPermitAvailable() {
      if (!this.location.DATE_PERMIT_APPROVED) return false
      return (
        dayjs().diff(this.location.DATE_PERMIT_APPROVED.unix_timestamp, 'day') <
        2
      )
    },
    isForPrint() {
      return this.$route.path === '/print-fiber-locations'
    },
  }, // computed
  methods: {
    ...mapActions(['setLocationHover', 'unsetLocationHover']),
    handleLocationItemClick() {
      if (this.isForPrint) return
      this.showLocationModal = true
    },
    hoverFn(locationId) {
      if (!this.isOnCalendar) return
      if (!this.hover) {
        this.setLocationHover(locationId)
      }
      this.hover = true
    },
    unhoverFn() {
      if (!this.isOnCalendar) return
      this.hover = false
      this.unsetLocationHover()
    },
  },
}
</script>

<style lang="scss">
.location-item {
  display: flex;
  flex: 1 0 280px;
  flex-wrap: wrap;
  align-self: flex-start;
  justify-content: center;
  max-width: calc(100% - 10px);
  padding: 10px;
  margin: 2.5px 5px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  // border-left: 4px solid transparent;
  // border-radius: 3px;

  &:hover {
    background-color: #e6e6e6;
  }

  &.is-on-cal {
    background: lighten(black, 90%);
    border: 2px solid lighten(black, 20%) !important;
    font-weight: bold;
    &:hover {
      background-color: lighten(black, 83%) !important;
    }
  }
  &.modal-open {
    color: black !important;
  }

  &.on-hold {
    .on-hold-icon {
      position: absolute;
      height: 35px !important;
      width: 35px !important;
      top: 2px;
      right: 2px;
    }
  }

  &.mini {
    .on-hold-icon {
      height: 24px !important;
      width: 24px !important;
    }
  }
}
</style>
